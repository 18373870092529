var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"relative my-10"},[_c('div',{staticClass:"max-w-screen-xl mx-auto pb-6 lg:pb-16"},[_c('div',{staticClass:"bg-white rounded-lg shadow"},[_c('div',{staticClass:"divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"},[_c('aside',{staticClass:"py-6 lg:col-span-3"},[_c('nav',[_c('router-link',{staticClass:"group mt-1 border-l-8 border-transparent px-3 py-2 flex items-center text-sm leading-5 font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900 transition ease-in-out duration-150 mx-1 rounded-md cursor-pointer",attrs:{"to":{name: 'communitySettingsGeneral'},"tag":"a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{class:{'router-link-active': isActive, 'router-link-inactive': !isActive},on:{"click":navigate}},[_c('svg',{staticClass:"flex-shrink-0 text-gray-400 -ml-1 mr-3 h-6 w-6 group-focus:text-gray-500 transition ease-in-out duration-150",class:{'router-link-active group-hover:text-gray-200': isActive, 'group-hover:text-gray-600': !isActive},attrs:{"aria-hidden":"true","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}})]),_c('span',{staticClass:"truncate"},[_vm._v(" General Settings ")])])]}}])}),_c('router-link',{staticClass:"group mt-1 border-l-8 border-transparent px-3 py-2 flex items-center text-sm leading-5 font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900 transition ease-in-out duration-150 mx-1 rounded-md cursor-pointer",attrs:{"to":{name: 'communitySettingsIntegrations'},"tag":"a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{class:{'router-link-active': isActive, 'router-link-inactive': !isActive},on:{"click":navigate}},[_c('svg',{staticClass:"flex-shrink-0 text-gray-400 -ml-1 mr-3 h-6 w-6 group-focus:text-gray-500 transition ease-in-out duration-150",class:{'router-link-active group-hover:text-gray-200': isActive, 'group-hover:text-gray-600': !isActive},attrs:{"aria-hidden":"true","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"}})]),_c('span',{staticClass:"truncate"},[_vm._v(" Integrations ")])])]}}])}),_c('router-link',{staticClass:"group mt-1 border-l-8 border-transparent px-3 py-2 flex items-center text-sm leading-5 font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900 transition ease-in-out duration-150 mx-1 rounded-md cursor-pointer",attrs:{"to":{name: 'communitySettingsCollaborators'},"tag":"a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{class:{'router-link-active': isActive, 'router-link-inactive': !isActive},on:{"click":navigate}},[_c('svg',{staticClass:"flex-shrink-0 text-gray-400 -ml-1 mr-3 h-6 w-6 group-focus:text-gray-500 transition ease-in-out duration-150",class:{'router-link-active group-hover:text-gray-200': isActive, 'group-hover:text-gray-600': !isActive},attrs:{"aria-hidden":"true","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"}})]),_c('span',{staticClass:"truncate"},[_vm._v(" Collaborators ")])])]}}])}),_c('router-link',{staticClass:"group mt-1 border-l-8 border-transparent px-3 py-2 flex items-center text-sm leading-5 font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:bg-gray-50 focus:text-gray-900 transition ease-in-out duration-150 mx-1 rounded-md cursor-pointer",attrs:{"to":{name: 'communitySettingsDelete'},"tag":"a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{class:{'router-link-active': isActive, 'router-link-inactive': !isActive},on:{"click":navigate}},[_c('svg',{staticClass:"flex-shrink-0 text-gray-400 -ml-1 mr-3 h-6 w-6 group-focus:text-gray-500 transition ease-in-out duration-150",class:{'router-link-active group-hover:text-gray-200': isActive, 'group-hover:text-gray-600': !isActive},attrs:{"aria-hidden":"true","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})]),_c('span',{staticClass:"truncate"},[_vm._v(" Delete ")])])]}}])})],1)]),_c('div',{staticClass:"divide-y divide-gray-200 lg:col-span-9 bg-gray-100 rounded-r-md",attrs:{"action":"#","method":"POST"}},[_c('router-view')],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }